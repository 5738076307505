


































import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { applicationStoreGetter } from '@/store/application.store';
import Company from '@/models/Company';
import { taxiStoreActions, taxiStoreGetter } from '@/store/taxi.store';
import Taxi from '@/models/Taxi';

const ApplicationStore = namespace('application');
const TaxiStore = namespace('taxi');

@Component({
    components: {
        TaxiTableComponent: () => import(
            /* webpackChunkName: "TaxiTableComponent" */
            '@/components/TaxiTable.component.vue'),
        EditTaxiComponent: () => import(
            /* webpackChunkName: "EditTaxiComponent" */
            '@/components/EditTaxi.component.vue')
    }
})
export default class TaxisView extends Vue {

    @ApplicationStore.Getter(applicationStoreGetter.SELECTED_COMPANY)
    public selectedCompany!: Company;

    @TaxiStore.Action(taxiStoreActions.LIST)
    public loadAllTaxis!: (payload: { company: Company }) => Promise<Taxi[]>;

    @TaxiStore.Getter(taxiStoreGetter.TAXIS)
    public allTaxis!: Taxi[];

    public searchValue: string = '';
    public showCreationDialog: boolean = false;

    public async created() {
        await this.loadAllTaxis({ company: this.selectedCompany });
    }

    public async closeCreationDialog(reload: boolean) {
        this.showCreationDialog = false;
        if (reload) {
            await this.loadAllTaxis({ company: this.selectedCompany });
        }
    }
}
